import * as React from "react";
import Layout from "../layouts/Layout";

const NotFoundPage = () => {
  const pageContext = { pagePath: "/404" };
  return (
    <Layout pageContext={pageContext}>
      <div className="container">
        <div className="max-w-screen-lg 2xl:max-w-screen-xl mx-auto 2xl:mx-0 pt-12">
          <h1 className="text-5xl text-gray-400">Error 404</h1>
          <h2 className="text-3xl">Page not found.</h2>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
